<template>
  <div class="verify-code-container">
    <div class="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 class="text-3xl font-bold text-center mb-6">Admin Verification</h1>
      
      <p class="text-center mb-6">Please enter the 6-digit verification code to access registrations</p>
      
      <div class="code-inputs flex justify-center space-x-2 mb-8">
        <input 
          v-for="(digit, index) in codeDigits" 
          :key="index"
          type="text" 
          maxlength="1"
          v-model="codeDigits[index]"
          @input="onDigitInput(index)"
          @keydown="onKeyDown($event, index)"
          ref="digitInputs"
          class="w-12 h-16 border-2 border-gray-300 rounded-md text-center text-2xl font-bold focus:border-blue-500 focus:outline-none"
        />
      </div>
      
      <div v-if="errorMessage" class="text-red-500 text-center mb-4">
        {{ errorMessage }}
      </div>
      
      <div class="text-center">
        <button 
          @click="verifyCode" 
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md"
          :disabled="loading"
        >
          <span v-if="loading">Verifying...</span>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
    
    <div v-if="showRegistrations" class="max-w-6xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <h2 class="text-2xl font-bold mb-4">Registrations</h2>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead>
            <tr>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Name</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Email</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">City</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">State</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Zip</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Status</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Nearby Schools</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reg in registrations" :key="reg._id" class="hover:bg-gray-50">
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.name }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.email }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.city }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.state }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.zip }}</td>
              <td class="py-2 px-4 border-b border-gray-200">
                <span 
                  :class="{
                    'px-2 py-1 rounded text-xs font-medium': true,
                    'bg-green-100 text-green-800': reg.payment_status === 'completed',
                    'bg-yellow-100 text-yellow-800': reg.payment_status === 'pending',
                    'bg-gray-100 text-gray-800': !reg.payment_status
                  }"
                >
                  {{ reg.payment_status || 'Unknown' }}
                </span>
              </td>
              <td class="py-2 px-4 border-b border-gray-200">
                <div v-if="reg.nearby_schools && reg.nearby_schools.length > 0">
                  <button 
                    @click="toggleSchoolsList(reg)" 
                    class="text-blue-600 hover:text-blue-800 text-sm font-medium"
                  >
                    {{ reg.nearby_schools.length }} schools
                    <span>{{ expandedSchools[reg._id] ? '▲' : '▼' }}</span>
                  </button>
                  <div v-if="expandedSchools[reg._id]" class="mt-2 space-y-1">
                    <div 
                      v-for="(school, idx) in reg.nearby_schools.slice(0, 3)" 
                      :key="idx" 
                      class="text-xs"
                    >
                      <span class="font-medium">{{ school.school_name }}</span> 
                      <span class="ml-1">({{ school.city }}) - </span>
                      <span 
                        :class="{
                          'text-green-600': school.distance < 30,
                          'text-yellow-600': school.distance >= 30 && school.distance < 60,
                          'text-red-600': school.distance >= 60
                        }"
                      >
                        {{ school.distance }} miles
                      </span>
                    </div>
                    <div v-if="reg.nearby_schools.length > 3" class="text-xs text-gray-500">
                      And {{ reg.nearby_schools.length - 3 }} more...
                    </div>
                  </div>
                </div>
                <div v-else-if="reg.coordinates">
                  <span class="text-gray-500 text-xs">None within 100 miles</span>
                </div>
                <div v-else>
                  <span class="text-gray-500 text-xs">No geocoding data</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyCodeView',
  data() {
    return {
      codeDigits: ['', '', '', '', '', ''],
      errorMessage: '',
      loading: false,
      showRegistrations: false,
      registrations: [],
      expandedSchools: {}
    }
  },
  methods: {
    async verifyCode() {
      // Combine digits into a single code
      const code = this.codeDigits.join('')
      
      // Check if all digits are filled
      if (code.length !== 6) {
        this.errorMessage = 'Please enter all 6 digits'
        return
      }
      
      this.loading = true
      this.errorMessage = ''
      
      try {
        const response = await fetch('/api/admin/verify-code', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ code })
        })
        
        const data = await response.json()
        
        if (data.success) {
          this.registrations = data.registrations
          this.showRegistrations = true
        } else {
          this.errorMessage = data.message
        }
        
      } catch (error) {
        console.error('Error verifying code:', error)
        this.errorMessage = 'An error occurred while verifying the code.'
      } finally {
        this.loading = false
      }
    },
    
    toggleSchoolsList(registration) {
      // Toggle expanded state for this registration
      this.expandedSchools = {
        ...this.expandedSchools,
        [registration._id]: !this.expandedSchools[registration._id]
      }
    },
    
    onDigitInput(index) {
      // Move to next input after filling current one
      if (this.codeDigits[index] && index < 5) {
        this.$refs.digitInputs[index + 1].focus()
      }
      
      // Ensure only numbers are entered
      this.codeDigits[index] = this.codeDigits[index].replace(/[^0-9]/g, '')
    },
    
    onKeyDown(event, index) {
      // Handle backspace to move to previous input
      if (event.key === 'Backspace' && !this.codeDigits[index] && index > 0) {
        this.$refs.digitInputs[index - 1].focus()
      }
    }
  },
  mounted() {
    // Focus first input when component mounts
    this.$nextTick(() => {
      this.$refs.digitInputs[0].focus()
    })
  }
}
</script>

<style scoped>
.verify-code-container {
  padding: 2rem;
  min-height: 100vh;
  background-color: #f9fafb;
}
</style> 